import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        pranishkedia.com
        <div></div>
        website coming soon.
      </header>
    </div>
  );
}

export default App;
